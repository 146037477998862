(() => {
	const IOtarget = document.querySelector(".strengths__list");

	if (IOtarget) {
		const IOoptions = {
			rootMargin: "0px",
			threshold: 0.25,
		};
		console.log(IOtarget);

		const IOcallback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("in-view");
				}
			});
		};

		const observer = new IntersectionObserver(IOcallback, IOoptions);
		observer.observe(IOtarget);
	}
})();
